import React from 'react';
import { Typography, Box, Container, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface ReleaseNote {
  version: string;
  title?: string;
  date: string;
  categories?: {
    title: string;
    changes: string[];
  }[];
  changes?: string[];
  patches?: {
    version: string;
    date: string;
    changes: string[];
  }[];
}

const releaseNotes: ReleaseNote[] = [
  {
    version: "1.0.1 (x)",
    title: "First Public TestFlight Release",
    date: "07/03/2025",
    categories: [
      {
        title: "New Features",
        changes: [
          "First public testflight build release",
          "Signup password UI improvements"
        ]
      },
      {
        title: "Bug Fixes",
        changes: [
          "Follow button functionality",
          "Follow back request for public accounts"
        ]
      },
      {
        title: "Improvements",
        changes: [
          "General UI enhancements",
          "Performance optimizations"
        ]
      }
    ],
    patches: [
      {
        version: "1.0.1 (4)",
        date: "07/03/2025",
        changes: [
          "Fixed bug that wouldnt allow comments to be created that didnt have a 'selectedSpotifyTrack'"
        ]
      },
      {
        version: "1.0.1 (3)",
        date: "07/03/2025",
        changes: [
          "Fixed follow back request for public accounts where it would request instead of follow"
        ]
      },
      {
        version: "1.0.1 (2)",
        date: "07/03/2025",
        changes: [
          "Fixed follow button functionality",
          "UI improvements"
        ]
      },
      {
        version: "1.0.1 (1)",
        date: "06/03/2025",
        changes: [
          "Initial public testflight release",
          "Updated signup password UI"
        ]
      }
    ]
  },
  {
    version: "1.0.0 (15)",
    title: "Personal Test Flight Release",
    date: "04/03/2025",
    categories: [
      {
        title: "Features",
        changes: [
          "Updates to Spotify scopes with granted approval",
          "Poll posts rendering in Post Lists"
        ]
      },
      {
        title: "Improvements",
        changes: [
          "General UI improvements",
          "Performance optimizations"
        ]
      },
      {
        title: "Bug Fixes",
        changes: [
          "Various bug fixes and stability improvements"
        ]
      }
    ]
  }
];

const ReleaseNotes = () => {
  const theme = useTheme();

  return (
    <Container>
      <Box sx={{ py: 6 }}>
        <Typography 
          variant="h3" 
          component="h1" 
          sx={{ 
            mb: 6,
            fontWeight: 300,
            textAlign: 'center'
          }}
        >
          Release Notes
        </Typography>

        {releaseNotes.map((release, index) => (
          <Box 
            key={release.version}
            sx={{ 
              mb: index !== releaseNotes.length - 1 ? 8 : 0,
              maxWidth: '800px',
              mx: 'auto'
            }}
          >
            {/* Entire version content with timeline */}
            <Box
              sx={{
                position: 'relative',
                pl: 4,
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  left: '7px',
                  top: '10px', // Align with the version title
                  bottom: 0,
                  width: '2px',
                  bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: '4px',
                  top: '10px', // Align with the version title
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  bgcolor: theme.palette.primary.main,
                }
              }}
            >
              {/* Version header */}
              <Box sx={{ mb: 3 }}>
                <Typography 
                  variant="h4"
                  sx={{ 
                    fontSize: '1.5rem',
                    fontWeight: 500,
                    mb: 1
                  }}
                >
                  {release.version}
                  {release.title && (
                    <Typography 
                      component="span" 
                      sx={{ 
                        ml: 2,
                        fontSize: '1.2rem',
                        fontWeight: 400,
                        color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'
                      }}
                    >
                      - {release.title}
                    </Typography>
                  )}
                </Typography>
                <Typography 
                  sx={{ 
                    fontSize: '0.9rem',
                    color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                    mb: 2
                  }}
                >
                  {release.date}
                </Typography>
              </Box>

              {/* Categories */}
              {release.categories?.map((category, catIndex) => (
                <Box key={catIndex} sx={{ mb: 3 }}>
                  <Typography 
                    variant="h5"
                    sx={{ 
                      fontSize: '1.1rem',
                      fontWeight: 500,
                      mb: 1.5,
                      color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.9)'
                    }}
                  >
                    {category.title}
                  </Typography>
                  <Box component="ul" sx={{ 
                    m: 0, 
                    p: 0,
                    pl: 2,
                    listStyle: 'none'
                  }}>
                    {category.changes.map((change, changeIndex) => (
                      <Typography 
                        key={changeIndex}
                        component="li"
                        sx={{ 
                          fontSize: '0.95rem',
                          color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
                          mb: 1,
                          '&:before': {
                            content: '"•"',
                            marginRight: '8px',
                            color: theme.palette.primary.main
                          }
                        }}
                      >
                        {change}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              ))}

              {/* Patches */}
              {release.patches && (
                <Box sx={{ mt: 4 }}>
                  <Typography 
                    variant="h6"
                    sx={{ 
                      fontSize: '1.1rem',
                      fontWeight: 500,
                      mb: 2,
                      color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)'
                    }}
                  >
                    Patches
                  </Typography>
                  {release.patches.map((patch, patchIndex) => (
                    <Box 
                      key={patch.version} 
                      sx={{ 
                        mb: patchIndex !== release.patches!.length - 1 ? 2 : 0,
                        pl: 2
                      }}
                    >
                      <Typography 
                        sx={{ 
                          fontSize: '1rem',
                          fontWeight: 500,
                          mb: 0.5
                        }}
                      >
                        {patch.version}
                      </Typography>
                      <Box component="ul" sx={{ 
                        m: 0, 
                        p: 0,
                        pl: 2,
                        listStyle: 'none'
                      }}>
                        {patch.changes.map((change, changeIndex) => (
                          <Typography 
                            key={changeIndex}
                            component="li"
                            sx={{ 
                              fontSize: '0.9rem',
                              color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
                              mb: 1,
                              '&:before': {
                                content: '"○"',
                                marginRight: '8px',
                                color: theme.palette.primary.main
                              }
                            }}
                          >
                            {change}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>

            {index !== releaseNotes.length - 1 && (
              <Divider sx={{ mt: 4 }} />
            )}
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default ReleaseNotes;