import React from 'react';
import { Box, useTheme } from '@mui/material';

interface SpinningLoaderProps {
  size?: number;
  duration?: number;
  variant?: 'white' | 'black';
  style?: React.CSSProperties;
}

const SpinningLoader: React.FC<SpinningLoaderProps> = ({ 
  size = 50, 
  style,
  duration = 2000,
  variant = 'white'
}) => {
  const theme = useTheme();

  const iconSrc = variant === 'black' 
    ? '/vinyl-icon-1-transparent-black-no-needle.png'
    : '/vinyl-icon-1-transparent-no-needle.png';

  return (
    <Box
      component="img"
      src={iconSrc}
      alt="Loading..."
      sx={{
        width: size,
        height: size,
        animation: `spin ${duration}ms linear infinite`,
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '100%': {
            transform: 'rotate(360deg)',
          },
        },
        ...style
      }}
    />
  );
};

export default SpinningLoader; 