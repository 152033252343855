import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  useTheme, 
  Alert,
  Snackbar
} from '@mui/material';

interface EmailSignupProps {
  title?: string;
  subtitle?: string;
}

const EmailSignup: React.FC<EmailSignupProps> = ({ 
  title = "Stay Updated", 
  subtitle = "Sign up to be notified about new test releases" 
}) => {
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    
    // Only validate if there's input
    if (newEmail) {
      setIsValidEmail(validateEmail(newEmail));
    } else {
      setIsValidEmail(true); // Reset validation when empty
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email) {
      setSnackbarMessage('Please enter your email address');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }
    
    if (!isValidEmail) {
      setSnackbarMessage('Please enter a valid email address');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch('https://0c9d8z8prd.execute-api.ap-southeast-2.amazonaws.com/prod/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to submit email');
      }

      // Show success message
      setSnackbarMessage('Thanks for signing up! We\'ll send you an invitation when available.');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      
      // Reset form
      setEmail('');

    } catch (error) {
      console.error('Error submitting email:', error);
      setSnackbarMessage(
        error instanceof Error 
          ? error.message 
          : 'An error occurred. Please try again.'
      );
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {subtitle}
        </Typography>
      </Box>
      
      <Box 
        component="form" 
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Your email address"
          value={email}
          onChange={handleEmailChange}
          error={!isValidEmail}
          helperText={!isValidEmail ? "Please enter a valid email address" : ""}
          disabled={isSubmitting}
          InputProps={{
            sx: {
              borderRadius: 2,
              backgroundColor: theme.palette.mode === 'dark' 
                ? 'rgba(0, 0, 0, 0.2)' 
                : 'rgba(255, 255, 255, 0.9)',
              height: 56,
              fontSize: '1.1rem'
            }
          }}
          sx={{ mb: 1 }}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          disabled={isSubmitting}
          sx={{
            borderRadius: 2,
            height: 56,
            fontWeight: 600,
            fontSize: '1rem'
          }}
        >
          {isSubmitting ? 'Submitting...' : 'Join TestFlight Beta'}
        </Button>
      </Box>
      
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EmailSignup; 