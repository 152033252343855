import React from 'react';
import { Typography, Box, Container, List, ListItem, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Beta = () => {
  const theme = useTheme();

  const steps = [
    {
      title: "1. Download TestFlight",
      description: "Install the TestFlight app from the App Store.",
      link: "https://apps.apple.com/us/app/testflight/id899247664"
    },
    {
      title: "2. Check Your Email",
      description: "Once approved, you'll receive an email with a TestFlight invitation link."
    },
    {
      title: "3. Open the Invitation",
      description: "Click the TestFlight invitation link in your email. This will open TestFlight on your device."
    },
    {
      title: "4. Accept & Install",
      description: "In TestFlight, tap 'Accept' and then 'Install' to download Soundcred Beta."
    },
    {
      title: "5. Start Using Soundcred",
      description: "Once installed, you can open and start using Soundcred. The app will automatically update when new beta versions are available."
    }
  ];

  return (
    <Container>
      <Box sx={{ 
        py: 6,
        maxWidth: '600px',
        mx: 'auto'
      }}>
        <Typography 
          variant="h3" 
          component="h1" 
          sx={{ 
            mb: 4,
            fontWeight: 300,
            textAlign: 'center'
          }}
        >
          Join the Beta
        </Typography>

        <Typography 
          sx={{ 
            fontSize: '1rem',
            color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
            mb: 6,
            textAlign: 'center'
          }}
        >
          Follow these steps to install Soundcred through TestFlight
        </Typography>

        <List sx={{ 
          '& > li': { 
            mb: 4,
            display: 'block'
          }
        }}>
          {steps.map((step, index) => (
            <ListItem key={index}>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  mb: 1
                }}
              >
                {step.title}
              </Typography>
              <Typography 
                sx={{ 
                  fontSize: '0.95rem',
                  color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
                  mb: step.link ? 1 : 0
                }}
              >
                {step.description}
              </Typography>
              {step.link && (
                <Link 
                  href={step.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    fontSize: '0.9rem',
                    color: theme.palette.primary.main,
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                >
                  Download TestFlight →
                </Link>
              )}
            </ListItem>
          ))}
        </List>

        <Box sx={{ mt: 6, textAlign: 'center' }}>
          <Typography 
            sx={{ 
              fontSize: '0.9rem',
              color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'
            }}
          >
            Need help? Contact me at{' '}
            <Link 
              href="mailto:support@soundcred.com"
              sx={{
                color: 'inherit',
                textDecoration: 'underline',
                '&:hover': {
                  color: theme.palette.primary.main
                }
              }}
            >
              support@soundcred.com
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Beta; 