import React from 'react';
import { Typography, Box, Container, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Contact = () => {
  const theme = useTheme();

  return (
    <Container>
      <Box sx={{ 
        textAlign: 'center', 
        py: 6,
        maxWidth: '400px',
        mx: 'auto'
      }}>
        <Typography 
          variant="h3" 
          component="h1" 
          sx={{ 
            mb: 3,
            fontWeight: 300
          }}
        >
          Contact
        </Typography>
        
        <Link 
          href="mailto:lachlan@soundcred.com"
          sx={{ 
            fontSize: '1rem',
            color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
            textDecoration: 'none',
            '&:hover': {
              color: theme.palette.primary.main
            }
          }}
        >
          lachlan@soundcred.com
        </Link>
      </Box>
    </Container>
  );
};

export default Contact;