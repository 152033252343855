import React, { useState, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container, CssBaseline, Box } from '@mui/material';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from "./pages/Home";
import ReleaseNotes from "./pages/ReleaseNotes";
// import Features from "./pages/Features";
import Contact from "./pages/Contact";
import TestFlight from "./pages/TestFlight";
import Beta from "./pages/Beta";

// Wrapper component to conditionally render Header and Footer
const AppContent = ({ toggleColorMode, mode }: { toggleColorMode: () => void, mode: 'light' | 'dark' }) => {
  const location = useLocation();
  const isTestFlightPage = location.pathname === '/testflight';

  return (
    <Box 
      className="App" 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        minHeight: '100vh'
      }}
    >
      {!isTestFlightPage && <Header toggleColorMode={toggleColorMode} mode={mode} />}
      <Container 
        component="main" 
        maxWidth={isTestFlightPage ? false : "lg"}
        disableGutters={isTestFlightPage}
        sx={{ 
          mt: isTestFlightPage ? 0 : 4, 
          mb: isTestFlightPage ? 0 : 4, 
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          pb: isTestFlightPage ? 0 : '60px',
          px: isTestFlightPage ? 0 : undefined
        }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/release-notes" element={<ReleaseNotes />} />
          {/* <Route path="/features" element={<Features />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/testflight" element={<TestFlight toggleColorMode={toggleColorMode} mode={mode} />} />
          <Route path="/beta" element={<Beta />} />
        </Routes>
      </Container>
      {!isTestFlightPage && <Footer />}
    </Box>
  );
};

function App() {
  const [mode, setMode] = useState<'light' | 'dark'>(() => {
    const savedMode = localStorage.getItem('colorMode');
    return (savedMode as 'light' | 'dark') || 'dark';
  });

  useEffect(() => {
    localStorage.setItem('colorMode', mode);
  }, [mode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#2196f3',
          },
          secondary: {
            main: '#f50057',
          },
        },
        typography: {
          fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
          h6: {
            fontWeight: 700,
          }
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                fontWeight: 600,
              },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: mode === 'dark' 
                  ? 'rgba(18, 18, 18, 0.8)'
                  : 'rgba(255, 255, 255, 0.8)',
              },
            },
          },
        },
      }),
    [mode]
  );

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppContent toggleColorMode={toggleColorMode} mode={mode} />
      </Router>
    </ThemeProvider>
  );
}

export default App;
