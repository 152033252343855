import React, { useEffect, useState } from 'react';
import { Typography, Box, Container, useTheme, IconButton } from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import EmailSignup from '../components/EmailSignup';
import SpinningLoader from '../components/SpinningLoader';

interface TestFlightProps {
  toggleColorMode?: () => void;
  mode?: 'light' | 'dark';
}

const TestFlight: React.FC<TestFlightProps> = ({ toggleColorMode, mode = 'dark' }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const validateToken = async () => {
      const accessToken = searchParams.get('token');
      
      if (!accessToken) {
        setIsAuthorized(false);
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/validate-token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: accessToken }),
        });

        if (response.status === 429) {
          const data = await response.json();
          setError('Too many attempts. Please try again later.');
          setIsAuthorized(false);
        } else {
          const data = await response.json();
          setIsAuthorized(data.isValid);
          setError(null);
        }
      } catch (error) {
        console.error('Error validating token:', error);
        setIsAuthorized(false);
        setError('An error occurred while validating your access. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    validateToken();
  }, [searchParams]);

  const UnauthorizedMessage = () => (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
        maxWidth: '440px',
        mx: 'auto'
      }}
    >
      <Typography 
        variant="h4" 
        sx={{ 
          mb: 3,
          fontWeight: 300
        }}
      >
        Access Restricted
      </Typography>
      <Typography
        sx={{
          color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
          fontSize: '1.1rem',
          mb: 3
        }}
      >
        {error || 'This page requires a valid access token. Please use the complete URL provided to you.'}
      </Typography>
      <Typography
        component={Link}
        to="/beta"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          color: theme.palette.primary.main,
          textDecoration: 'none',
          fontSize: '0.95rem',
          '&:hover': {
            textDecoration: 'underline'
          }
        }}
      >
        For installation instructions, click here →
      </Typography>
    </Box>
  );

  if (isLoading) {
    return (
      <Container 
        maxWidth={false} 
        sx={{ 
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2
        }}
      >
        <SpinningLoader 
          size={40}
          variant={theme.palette.mode === 'dark' ? 'white' : 'black'}
        />
      </Container>
    );
  }

  return (
    <Container 
      maxWidth={false} 
      sx={{ 
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        pt: 12,
        px: { xs: 3, sm: 4, md: 5 }
      }}
    >
      {/* Logo in top left */}
      <Box 
        sx={{ 
          position: 'fixed',
          top: 32,
          left: { xs: 24, sm: 32 }
        }}
      >
        <Typography 
          variant="h6" 
          component={Link} 
          to="/"
          sx={{ 
            textDecoration: 'none', 
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
            fontWeight: 600,
            letterSpacing: 0.5,
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              opacity: 0.8
            }
          }}
        >
          <img 
            src={theme.palette.mode === 'dark' ? '/vinyl-icon-1-transparent-no-needle.png' : '/vinyl-icon-1-transparent-black-no-needle.png'} 
            alt="Soundcred Logo" 
            style={{ width: 24, height: 24, marginRight: 8 }}
          />
          SOUNDCRED
        </Typography>
      </Box>

      {/* Theme toggle in top right */}
      {toggleColorMode && (
        <Box 
          sx={{ 
            position: 'fixed',
            top: 32,
            right: { xs: 24, sm: 32 }
          }}
        >
          <IconButton 
            onClick={toggleColorMode} 
            sx={{ 
              color: theme.palette.mode === 'dark' ? '#fff' : '#000',
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'rotate(90deg)',
                backgroundColor: 'transparent'
              }
            }}
          >
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </Box>
      )}

      {isAuthorized ? (
        <Box 
          sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '640px',
            mx: 'auto',
            width: '100%',
            flex: 1,
            mt: -12.5
          }}
        >
          <Box sx={{ width: '100%', maxWidth: '440px' }}>
            <EmailSignup 
              title="Get TestFlight Access"
              subtitle="We'll send you an invitation when available to you"
            />
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Typography
                component={Link}
                to="/beta"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                  fontSize: '0.95rem',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                For installation instructions, click here →
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <UnauthorizedMessage />
      )}
    </Container>
  );
};

export default TestFlight; 